<template>
  <div class="avatar" :style="`background:${getBackground}`">B</div>
</template>

<script lang="ts">
export default defineComponent({
  name: 'AvatarBusiness',
  props: {
    companyName: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const { name } = storeToRefs(useBusinessStore())

    const businessName = computed(() => props.companyName ?? name.value)

    return { businessName }
  },
  computed: {
    backgroundColors() {
      return [
        this.$vuetify.theme.themes.light['avatar-0'] as string,
        this.$vuetify.theme.themes.light['avatar-1'] as string,
        this.$vuetify.theme.themes.light['avatar-2'] as string,
        this.$vuetify.theme.themes.light['avatar-3'] as string,
        this.$vuetify.theme.themes.light['avatar-4'] as string,
        this.$vuetify.theme.themes.light['avatar-5'] as string,
        this.$vuetify.theme.themes.light['avatar-6'] as string,
        this.$vuetify.theme.themes.light['avatar-7'] as string,
        this.$vuetify.theme.themes.light['avatar-8'] as string,
        this.$vuetify.theme.themes.light['avatar-9'] as string,
      ]
    },
    getBackground() {
      const companyName = this.businessName
      let hash = 0
      let chr: number

      // set default color and give users with skribble email the black insignia
      if (companyName === null || companyName.length === 0) {
        return this.$vuetify.theme.themes.light['avatar-0'] as string
      }

      // set hash based color
      for (let i = 0; i < companyName.length; i++) {
        chr = companyName.charCodeAt(i)
        hash = (hash << 5) - hash + chr
        // Convert to 32bit integer
        hash |= 0
      }

      return this.backgroundColors[Math.abs(hash % 10)]
    },
  },
})
</script>

<style scoped lang="sass">

$ns: avatar

.#{$ns}
  position: relative
  display: flex
  align-items: center
  justify-content: center
  width: 40px
  height: 40px
  border-radius: 3px
  background-color: $c-avatar-0
  color: $c-white
  font-weight: bold
</style>
