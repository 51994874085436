<template>
  <!--
    absolute:       on tablets and up
    app:            not for overlay (is not part of app layout)
    clipped-right:  to extend to entire width within overlay
    fixed:          on phones when not within an overlay
  -->
  <component
    :is="baseComponent"
    :absolute="$vuetify.display.smAndUp"
    class="sh"
    :class="{ 'is-dismissible': dismissible }"
    :color="$vuetify.display.smAndUp ? 'transparent' : 'white'"
    :fixed="$vuetify.display.xs && !overlay"
    :height="$vuetify.display.xs ? '70' : '90'"
    flat
  >
    <!-- Dismissible header / most overlays -->
    <template v-if="dismissible && !isDirectSign">
      <slot name="left">
        <v-btn class="sh__close sh__close_hover" variant="text" data-cy="closeHeader" height="100%" @click="close">
          <v-icon size="24">custom:c_close</v-icon>
        </v-btn>
      </slot>
      <div class="sh__title">
        <slot />
      </div>
      <v-row justify="end">
        <slot name="right" />
      </v-row>
    </template>

    <!-- Regular logged-in header
      Overlays will end up here but should not display anything else then
      the logo centered. So we need to introduce some artificial elements
      within the left slot to make this happen.
    -->
    <template v-else-if="userId && !usePlainHeader">
      <slot name="left">
        <template v-if="$vuetify.display.smAndDown && !overlay">
          <v-btn class="sh__main-nav-trigger ml-0" icon :ripple="false" @click.stop="$emit('clicked')">
            <span />
          </v-btn>
        </template>
        <div v-else-if="$vuetify.display.xs" />
      </slot>

      <!-- For overlays only display logo. Don't show a clickable logo (href) -->
      <template v-if="overlay">
        <div v-if="business" class="text-no-decoration">
          <logo data-cy="indexLogo" class="sh__logo" business />
        </div>
        <div v-else class="text-no-decoration">
          <logo data-cy="indexLogo" class="sh__logo" :image="brandingLogoUrl" />
        </div>
      </template>

      <template v-else>
        <nuxt-link v-if="business" :to="{ name: 'business-profile' }" class="text-no-decoration">
          <logo data-cy="indexLogo" class="sh__logo" business />
        </nuxt-link>
        <nuxt-link v-else-if="!isLogin" to="/" class="text-no-decoration">
          <logo data-cy="indexLogo" class="sh__logo" :image="brandingLogoUrl" />
        </nuxt-link>
      </template>

      <slot name="right">
        <template v-if="$vuetify.display.xs && !overlay">
          <v-btn
            class="avatar-btn pa-0"
            min-width="auto"
            :ripple="false"
            variant="text"
            :to="{ name: business ? 'business-profile' : 'profile' }"
          >
            <avatar-business v-if="business" />
            <avatar v-else />
          </v-btn>
        </template>
        <div v-else-if="$vuetify.display.xs" />
      </slot>
    </template>

    <!-- Logged out / public header / 404 page -->
    <template v-else>
      <slot name="left">
        <nuxt-link v-if="iconLink" class="skr-logo" :to="{ path: '/' }">
          <nuxt-img src="/img/logo-skribble.svg" width="54" height="30" alt="Skribble" />
          <div>Skribble</div>
        </nuxt-link>
        <div v-else class="skr-logo">
          <nuxt-img src="/img/logo-skribble.svg" width="54" height="30" alt="Skribble" />
          <div>Skribble</div>
        </div>
      </slot>
      <v-row justify="end">
        <slot name="right" />
        <template v-if="loginUpperRight && $vuetify.display.mdAndUp">
          <span class="mr-4 align-self-center">{{ $t('signup.already_have_account') }}</span>
          <v-btn :to="{ name: 'login' }" size="large" color="info" variant="outlined">
            {{ $t('global.login') }}
          </v-btn>
        </template>
      </v-row>
    </template>
  </component>
</template>

<script lang="ts">
import { VToolbar, VAppBar } from 'vuetify/components'

import Avatar from '@/components/Avatar.vue'
import AvatarBusiness from '@/components/AvatarBusiness.vue'
import Logo from '@/components/Logo.vue'

export default defineComponent({
  name: 'SiteHeader',
  components: {
    Avatar,
    AvatarBusiness,
    Logo,
    VToolbar,
    VAppBar,
  },
  props: {
    action: {
      type: Function,
      required: false,
    },
    business: Boolean,
    dismissible: {
      type: Boolean,
      default: false,
    },
    iconLink: {
      type: Boolean,
      default: true,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
    usePlainHeader: {
      type: Boolean,
      default: false,
    },
    loginUpperRight: {
      type: Boolean,
      default: false,
    },
    overlay: {
      type: Boolean,
      default: false,
    },
    to: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    const route = useRoute()

    const businessStore = useBusinessStore()
    const { url: srBrandingLogoUrl } = storeToRefs(useSrBrandingStore())
    const { id: userId } = storeToRefs(useUserStore())
    const { isDirectSign } = useDirectSignStore()
    const { resetSnackbars } = useWidgetStore()

    const isLogin = computed(() => route.name === 'login')

    // The logo in the top left has the following priority:
    // 1. company branding of logged in user
    // 2. company branding of current signatureRequest and an overlay is shown at the moment
    // 3. Skribble logo which is chosen if empty string is sent
    const brandingLogoUrl = computed(() => {
      const logoUrl = businessStore.settings.companyBranding?.logo?.url

      if (logoUrl) {
        return logoUrl
      } else if (srBrandingLogoUrl.value && props.overlay) {
        return srBrandingLogoUrl.value
      } else {
        return ''
      }
    })

    const close = async () => {
      resetSnackbars()
      if (props.action) props.action()
      if (props.to) await navigateTo(props.to)
    }

    const baseComponent = computed(() => {
      return props.overlay ? VToolbar : VAppBar
    })

    return {
      baseComponent,
      isLogin,
      userId,
      isDirectSign,
      brandingLogoUrl,
      close,
    }
  },
})
</script>

<style lang="sass">
.sh
  z-index: 1
  .avatar
    +maw(xs)
      width: 34px
      height: 34px
      margin-right: 4px

  .avatar-btn
    width: 40px
    height: 40px
    min-width: 0
    margin: 0
    padding: 0
    font-size: 0.875rem
    &.v-btn--active:before
      display: none

  .v-toolbar__content
    display: flex
    justify-content: space-between
    align-items: center
    padding: 0 1.5rem

  &.is-batch-header
    display: flex
    justify-content: center

    &.v-theme--light
      background: $c-primary

  &.push-down
    top: 90px

  &.is-dismissible

    .v-toolbar__content
      border-bottom: 1px solid $c-border

  &__logo
    // padding: 0 16px
    &:first-child
      margin-left: 16px

  &__main-nav-trigger
    display: flex
    justify-content: center
    align-items: center
    width: 60px
    height: 60px
    border-radius: 0
    cursor: pointer
    &.v-size--default:not(.v-btn--round)
      min-width: auto
    +maw(sm)
      width: 32px
    &:hover
      .v-btn__overlay
        background-color: $c-primary
        &:before,
        &:after
          background-color: $c-primary
    &.v-btn
      min-width: 0
      padding: 0
      margin: 0

    .v-btn__content
      position: relative
      display: block
      width: 26px
      height: 3px
      border-radius: 1.5px
      background-color: $c-text
      +maw(sm)
        max-width: 22px
      &:before,
      &:after
        position: absolute
        left: 0
        width: 26px
        height: 3px
        border-radius: 1.5px
        background-color: $c-text
        content: ''
        +maw(sm)
          width: 22px
      &:before
        top: -8px
        +maw(sm)
          top: -7px
      &:after
        bottom: -8px
        +maw(sm)
          bottom: -7px

  &__title
    overflow: hidden
    text-overflow: ellipsis
    color: $c-text-alt

  &__profile
    min-width: 0
    width: 60px
    height: 60px
    padding: 0
    &:before
      content: normal

  &__signing-status
    position: absolute
    right: 2px
    top: -2px
    width: 16px
    height: 16px

  &__close.v-btn
    position: relative
    left: -24px
    width: $siteheader-height
    height: $siteheader-height
    margin: 0
    padding: 0
    border-right: 1px solid $c-border
    border-radius: 0
    color: black
    +maw(xs)
      height: 70px

    .v-btn
      &__content
        color: $c-text

    .v-icon
      margin: 0

  &__close_hover
    &:before
      background: $c-primary !important
    &:hover
      .v-btn__content
        color: $c-primary !important
</style>
